import { ReactNode } from 'react'
import { Plus_Jakarta_Sans } from 'next/font/google'

import { BaseFontProvider } from './base-font-provider'

const plusJakartaSans = Plus_Jakarta_Sans({
  weight: ['200', '300', '400', '500', '600', '700', '800'],
  subsets: ['latin'],
  display: 'swap',
})

export const PlusJakartaSansProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  return <BaseFontProvider font={plusJakartaSans}>{children}</BaseFontProvider>
}
