import * as R from 'ramda'

import CurrentUserProvider from 'providers/current-user-provider'
import { withApollo } from 'hoc/with-apollo'
import urls from 'utilities/urls'
import redirect from 'utilities/redirect'
import bugsnagClient from 'clients/bugsnag'
import contentfulClient from 'clients/contentful'
import Layout from 'components/layout'
import Homepage from 'components/homepage'
import ErrorPage from './_error'
import { getClientBySlug } from './queries.graphql'
import ClientReferralProvider from '../providers/client-referral-provider'
import { HOMEPAGE_CONTENTFUL_SLUG } from 'constants/experiments'
import { getExperimentKitSSR } from 'utilities/experiment/server'
import { useExperimentKit } from 'utilities/experiment/context'
import PROJECTS from 'utilities/experiment/constants'
import { getFirstStringFromOptionalArray } from 'utilities/strings'
import { HeeboProvider } from 'providers/font-providers/heebo-provider'
import { PlusJakartaSansProvider } from 'providers/font-providers/plus-jakarta-sans-provider'

export const SURVEY_ID = 3

const LandingPage = ({
  statusCode,
  slug,
  signUpVariant,
  survey,
  query = {},
  fields = {} as any,
  clientFirstName,
  isMobile,
  experimentKitData,
  preAssignedSSRExperiments,
  referrer,
  isFreeTrial,
  contentSlug,
}) => {
  const { sections, title, metaTags, noIndex, canonicalUrl, options } = fields

  const appStyles = options?.appStyles || {}

  const ek = useExperimentKit(experimentKitData, preAssignedSSRExperiments)
  const homepageUpgradeSplit = ek.split('homepageUpgrade')

  if (statusCode !== 200) {
    return <ErrorPage statusCode={statusCode} />
  }

  const FontProvider =
    homepageUpgradeSplit !== 'A' ? PlusJakartaSansProvider : HeeboProvider

  return (
    <>
      <FontProvider>
        <Layout
          title={title}
          metaTags={metaTags}
          noIndex={noIndex}
          canonicalUrl={canonicalUrl}
          includeChat={true}
          signUpVariant={signUpVariant}
          clientFirstName={clientFirstName}
          referrer={referrer}
          isFreeTrial={isFreeTrial}
          appStyles={appStyles}>
          <ClientReferralProvider referrer={{ clientFirstName }}>
            <CurrentUserProvider shouldIdentifyUser={false}>
              <Homepage
                sections={sections}
                survey={survey}
                signUpVariant={signUpVariant}
                query={query}
                surveyId={SURVEY_ID}
                fields={fields}
                slug={slug}
                isMobile={isMobile}
                contentSlug={contentSlug}
              />
            </CurrentUserProvider>
          </ClientReferralProvider>
        </Layout>
      </FontProvider>
      <style jsx global>{`
        body {
          overflow-x: hidden;
        }

        body #crisp-chatbox > div > a > span {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
        }
      `}</style>
    </>
  )
}

LandingPage.getInitialProps = async ({ ctx, apolloClient }) => {
  const {
    query,
    query: { slug = HOMEPAGE_CONTENTFUL_SLUG },
    req,
    res,
  } = ctx
  const ref = getFirstStringFromOptionalArray(query.ref)

  res &&
    res.setHeader(
      'cache-control',
      `s-maxage=${60 * 60}, stale-while-revalidate`
    )

  let userAgent, referrer
  if (req) {
    userAgent = req.headers['user-agent']
    referrer = req.headers.referer
  } else {
    userAgent = navigator.userAgent
  }

  const isMobile = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  )

  const [platform, freetrial, clientSlug] = ref?.split('_') || []
  let clientFirstName

  const isFreeTrial = !!freetrial && platform !== 'gp' && !clientSlug

  if (platform === 'gp' && clientSlug) {
    const clientPayload = await apolloClient.query({
      query: getClientBySlug,
      variables: { slug: clientSlug },
    })

    clientFirstName = R.pathOr(
      undefined,
      ['data', 'clientBySlug', 'firstName'],
      clientPayload
    )
  }

  const { ek, data } = await getExperimentKitSSR(ctx, {
    url: ctx.asPath,
    attributes: {
      projectId: PROJECTS.WEB,
      url: ctx.asPath,
      paidTrial: ctx.req?.cookies?.paidTrial,
    },
    devMode: process.env.NODE_ENV === 'development',
  })

  const isLandingPageExperiment = ek.getLandingPageExperiment(slug)
  const landingPageSlug = ek.getExperimentValue(isLandingPageExperiment?.key)
  const preAssignedSSRExperiments = ek.getAssigned()

  let contentSlug = slug

  if (clientFirstName) {
    contentSlug = '_guestpass'
  } else if (isLandingPageExperiment) {
    contentSlug = landingPageSlug
  }

  const [payload, surveyPayload] = await Promise.all([
    contentfulClient.getEntries({
      content_type: 'landingPage',
      'fields.slug[in]': contentSlug,
      include: 10,
    }),
    contentfulClient.getEntries({
      content_type: 'survey',
      'fields.id[in]': SURVEY_ID,
      include: 10,
    }),
  ])

  const config = payload.items[0]

  if (!config) {
    bugsnagClient.notify(new Error(`Missing homepage slug: ${slug}`))
    // redirect to standard homepage unless request is for a file we're missing
    if (!contentSlug.includes('.')) {
      redirect(urls.homePage(R.omit(['slug'], query)).as, ctx)
    }

    return { statusCode: 404 }
  }

  return {
    statusCode: 200,
    slug,
    fields: config.fields,
    survey: surveyPayload.items[0],
    query,
    clientFirstName,
    isMobile,
    experimentKitData: data,
    preAssignedSSRExperiments,
    referrer,
    isFreeTrial,
    contentSlug,
  }
}

export default withApollo(LandingPage)
