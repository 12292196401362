import uuid from 'uuid/v4'
import { DAY } from 'constants/seconds'
import { NextPageContext } from 'next'
import { parseCookies, setCookie } from 'nookies'
import { ExperimentKit } from '.'
import { Context, ExperimentDefinition } from './types'

export type ExperimentKitSSRData = {
  attributes: {
    projectId: string
    [key: string]: any
  }
  experiments: Record<string, ExperimentDefinition>
  disabledExperiments: string[]
}

export type ExperimentKitSSR = {
  ek?: ExperimentKit
  data: ExperimentKitSSRData
}

export async function getExperimentKitSSR(
  ctx: NextPageContext | unknown,
  context: Context
): Promise<ExperimentKitSSR> {
  const { tempId } = parseCookies(ctx)

  if (!tempId) {
    const id = context.user ? context.user.id : uuid()
    setCookie(ctx, 'tempId', id, {
      path: '/',
      maxAge: DAY,
    })

    context = {
      ...context,
      attributes: { ...context.attributes, id },
    }
  } else {
    context = {
      ...context,
      attributes: { ...context.attributes, id: tempId },
    }
  }
  const ek = new ExperimentKit({ ...context })

  await ek.loadExperiments()

  const data: ExperimentKitSSRData = {
    attributes: ek.getAttributes(),
    experiments: ek.getExperiments(),
    disabledExperiments: ek.getDisabledExperiments(),
  }

  // ek.destroy()

  return { ek, data }
}
